/**
 * Components scripts.
 */

/**
 * Let's import jQuery from global namespace.
 */
import $ from 'jQuery';

let isMenuOpen = false;
let targets = [];
let hamburgerHeight = 0;
let wasScroll = false;

var init = function () {
    hamburgerHeight = $( '.hamburger-box' ).offset().top;
    /// /findTargetsForBackgroundChange();
    attachListnersForMenuButton();
    triggerBackgroundChangeEvent();
    $( window ).scroll( triggerBackgroundChangeEventLimiter );

    $( 'body' ).on('sectionsResize', function() {
        findTargetsForBackgroundChange();
    });

    setInterval(triggerBackgroundChangeEvent, 300);

    $( window ).load(findTargetsForBackgroundChange());
};


function findTargetsForBackgroundChange() {
    targets = [];
    $( '.section-bio, .section-schedule, .recordings' ).each( function () {
        targets.push( {
            point: $( this ).offset().top - hamburgerHeight,
            dark: true
        } );
    } );
    $( '.section-news, .splash, .splash-newcd' ).each( function () {
        targets.push( {
            point: $( this ).offset().top - hamburgerHeight,
            dark: false
        } );
    } );

    targets.sort( function ( a, b ) {
        return a.point - b.point;
    } );

}

function triggerBackgroundChangeEventLimiter() {
    wasScroll = true;
}

function triggerBackgroundChangeEvent() {
    if(wasScroll) {
        wasScroll = false;
        let top = $( window ).scrollTop();

        for ( var t = 0; t < targets.length; t = t + 1 ) {
            if ( top >= targets[ t ].point && ( t + 1 === targets.length || top < targets[ t + 1 ].point ) ) {
                $( '.menu' ).trigger( 'backgroudChange', targets[ t ].dark );
                return;
            }
        }
    }
}

function attachListnersForMenuButton() {
    $( '.menu__button, .menu__item' ).on( 'click', function () {
        $( '.menu__popup' ).toggleClass( 'menu__popup--active' );
        $( '.menu__button' ).toggleClass( 'is-active' );
        $( '.hamburger-label' ).toggle();
        $( '.hamburger-inner' ).toggleClass( 'hamburger-inner--white-click' );


        isMenuOpen = !isMenuOpen;
    } );

    $( '.menu' ).on( 'backgroudChange', function ( event, isDark ) {
        if ( isMenuOpen === false ) {
            if ( isDark ) {
                $( '.hamburger-inner' ).addClass( 'hamburger-inner--white' );
                $( '.hamburger-label' ).addClass( 'hamburger-label--white' );
            } else {
                $( '.hamburger-inner' ).removeClass( 'hamburger-inner--white' );
                $( '.hamburger-label' ).removeClass( 'hamburger-label--white' );
            }
        }
    } );
}


export { init };
