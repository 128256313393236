import $ from 'jQuery';

const flippedClass = 'picture-flip__side--flipped';

var init = function () {

    $( '.picture-flip__container' ).on( 'click', function( e ) {
        if ( !$( e.target ).hasClass( 'button' ) ) {
            let that = $( this );
            if(that.hasClass( flippedClass )) {
                that.removeClass( flippedClass );
            } else {
                $( '.picture-flip__container').trigger('turnTop', [ that ]);
                that.addClass( flippedClass );
            }
        }
    } );

    $( '.picture-flip__container').on('turnTop', function( e, except ) {
        //if($( this ) != except)
            $( this ).removeClass( flippedClass );
    })

};

export { init };
