import $ from 'jQuery';


let hidePopups = function( activeClass ) {

    $( '.' + activeClass ).each( function() {
        hidePopup( $( this ), activeClass );
    } );
};


let expandable = function( popUpSelector, activeClass, inactiveClass, wasInactiveClass, callbackOff, callbackOn ) {
    $( popUpSelector ).click( function( ) {
        let $this = $( this );
        if ( $this.hasClass( activeClass ) ) {
            hidePopup( $this, activeClass );
            if( $.isFunction( callbackOff ) ) {
                callbackOff( $this );
            }
        } else {
            $this.addClass(activeClass);
            $this.parent().removeClass(wasInactiveClass);

            $this.parent().siblings().each(function () {
                hidePopup( $( this ).children(popUpSelector), activeClass );
                $( this ).addClass(inactiveClass);
            });
            $this.children().trigger('showPopup');

            if( $.isFunction( callbackOn ) ) {
                callbackOn( $this );
            }
        }
    } );
};

let hidePopup = function( element, activeClass ) {
    element.removeClass( activeClass );
    element.children().trigger( 'hidePopups' );
};

let inactiveHover = function( selector, inactiveClass, wasInactiveClass ) {
    $( selector ).hover(
        function() {
            if ( $( this ).hasClass( inactiveClass ) ) {
                $( this ).removeClass( inactiveClass );
                $( this ).addClass( wasInactiveClass );
            }
        },
        function() {
            if ( $( this ).hasClass( wasInactiveClass ) ) {
                $( this ).removeClass( wasInactiveClass );
                $( this ).addClass( inactiveClass );
            }
        } );
};

export { expandable, hidePopup, hidePopups, inactiveHover };
