/*jshint -W031 */
import Swiper from 'Swiper';

let init = function() {
    initSwiper();
};
const gallerySelector = '.portraits__gallery';
var initSwiper = function() {
    let portraitsSwiper = new Swiper( gallerySelector, {
        nextButton: '.portraits__button--prev',
        prevButton: '.portraits__button--next',
        slidesPerView: 'auto',
        lazyLoading: true,
        lazyLoadingInPrevNextAmount: 2
    } );

    $( gallerySelector).imagesLoaded( function() {
        if(portraitsSwiper) {
            portraitsSwiper.update();
        }
    } );
};


export { init };
