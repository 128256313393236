/**
 * Components scripts.
 */

/**
 * Let's import jQuery from global namespace.
 */
import $ from 'jQuery';
var init = function() {

    $( '.schedule-item' ).on( 'hidePopups', function() {
        setInactive( $( this ) );
    } );

    $( '.schedule-item' ).on( 'showPopup', function() {
        setActive( $( this ) );
    } );

};


var setInactive = function( element ) {
    element.children( '.schedule-item__calendar' ).addClass( 'schedule-item__calendar--inactive' );
    element.children( '.schedule-item__button' ).addClass( 'schedule-item__button--inactive' );
    element.children( '.schedule-item__title' ).children( '.subheadline' ).removeClass( 'subheadline--primary' );
};

var setActive = function( element ) {
    element.children( '.schedule-item__calendar' ).removeClass( 'schedule-item__calendar--inactive' );
    element.children( '.schedule-item__button' ).removeClass( 'schedule-item__button--inactive' );
    element.children( '.schedule-item__title' ).children( '.subheadline' ).addClass( 'subheadline--primary' );
};

export { init };
