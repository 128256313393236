import Swiper from 'Swiper';
import * as pop from '../pop-item/_pop-item';

let scheduleSwiper;

const activeClass = 'section-schedule__pop--active';
const inactiveClass = 'section-schedule__item--inactive';
const wasInactiveClass = 'section-schedule__item--wasInactive';
const popUpSelector = '.section-schedule__pop';

let init = function() {
    initSwiper($('.section-schedule').data('first'));
    pop.inactiveHover( '.section-schedule__item', inactiveClass, wasInactiveClass );
    pop.hidePopups( activeClass );
    pop.expandable( popUpSelector, activeClass, inactiveClass, wasInactiveClass, removeReducedOpacity );

};

var initSwiper = function(slide) {
    scheduleSwiper = new Swiper( '.section-schedule__container', {
        // Optional parameters
        //direction: 'vertical',
        //loop: true,
        // Navigation arrows
        nextButton: '.section-schedule__button--next',
        prevButton: '.section-schedule__button--prev',
        slidesPerView: 4,
        initialSlide: slide ? slide : 0

    } );
};

var removeReducedOpacity = function() {
    $( '.'+inactiveClass ).removeClass( inactiveClass );
};

export { init };
