import $ from 'jQuery';

var init = function() {


    $( '.section-bio__more-button' ).click( function() {
        $( '.section-bio__more' ).toggleClass( 'section-bio__more--hidden' );
        $( this ).hide();
    } );


};



export { init };
