import $ from 'jQuery';
import Modernizr from 'Modernizr';
import svg4everybody from 'svg4everybody';
/**
 * Module containing polyfills initialization.
 */

/**
 * Replaces SVG images with their PNG replacements.
 * The idea is to basically provide PNG versions of the most essential images on
 * the page e.g. main logo, navigation trigger etc. that sould be placed in the
 * same directory as the SVG file and have the same name.
 */
var svgToPng = function() {
    var $svgImage;
    $( 'img[src$=".svg"]' ).each( function() {
        $svgImage = $( this );
        $svgImage.attr( 'src', $svgImage.attr( 'src' ).replace( 'svg', 'png' ) );
    } );
};

/**
 * Initializes all polyfills used on the page, can be used to load polyfills again
 * when loading content dynamically.
 */
var init = function() {
    /**
     * If SVG graphics are supported by the browser.
     */
    if ( Modernizr.svg ) {
        /**
         * Polyfill using external SVGs if needed(scripts checks that).
         */
        svg4everybody();
    } else {
        /**
         * Replace all references to SVG files with their PNG replacements.
         */
        svgToPng();
    }
};

export { init };
