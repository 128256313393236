import $ from 'jQuery';

let paper = $('.paper');
let paperCard = $('.paper__card');
let paperContent = $('.paper__content');

let isOpen = false;

let whereIsIt = 0;


const open = function() {
    const margin = 30;

    //if(!isOpen) {
        whereIsIt = $( window ).scrollTop();
    //}

    paperCard.css('visibility', 'hidden');
    paperCard.css('display', 'block');

    let height = paperContent.outerHeight();

    let windowHeight = $( window ).innerHeight();
    let bodyHeight = $( 'body' ).outerHeight();

    let paperHeight = windowHeight > height ? windowHeight : height;
    let pixelsLeft = bodyHeight - whereIsIt;
    let top = whereIsIt;
    //if( pixelsLeft < height ) {
    //    top = bodyHeight - height;
    //    if(top<5) {
    //        top=5;
    //    }
    //}

    paperCard.css( 'height', paperHeight );
    paperCard.css( 'top', top + margin );
    paper.css( 'margin-top', - top );
    paper.css( 'height', paperHeight + top + 2 * margin );
    //paper.css( 'overflow', 'hidden' );
    paper.addClass('paper--active');
    paperCard.css( 'visibility', 'visible' );

    isOpen = true;
    $( window ).scrollTop( 0 );
    $( 'body').trigger('sectionsResize');

};

const close = function() {
    paperCard.hide();
    //paper.css( 'overflow', 'visible' );
    paper.css( 'height', 'auto' );
    paper.css( 'margin-top', 0 );
    paper.removeClass('paper--active');
    isOpen = false;
    $( window ).scrollTop( whereIsIt );
    $( 'body').trigger('sectionsResize');
};

const listen = function() {
    $('.paper').on('showPaper', function(e, data, callbacks) {
        if( isOpen ) close();
        paperContent.html(data);
        enableButton(callbacks.next, '.paper__button--next');
        enableButton(callbacks.prev, '.paper__button--prev');
        setTimeout(open, 10); //Leo why... this sux!
    });
    $('.paper').on('hidePaper', function(e) {
        close();
    });

    $('.paper__button--close').click(function(){
        close();
    });

    $( 'body' ).keydown(function(e) {
        if( isOpen && e.keyCode === 27 ) {
            close();
        }
    });

    $(document).bind( "mouseup touchend", function(e){
        if (isOpen && !paperCard.is(e.target) // if the target of the click isn't the container...
            && paperCard.has(e.target).length === 0) // ... nor a descendant of the container
        {
            close();
        }
    });
};

const enableButton = function( callback, selector ) {
    let button = $( selector );
    if(callback) {
        button.click( callback );
        button.css('visibility','visible');
    } else {
        button.css('visibility','hidden');
    }
};

export { listen };