import Swiper from 'Swiper';
import * as pop from '../pop-item/_pop-item';


const elementName = 'stage-photos';

const activeClass = elementName + '__pop--active';
const inactiveClass = elementName + '__item--inactive';
const wasInactiveClass = elementName + '__item--wasInactive';
const popUpSelector = '.' + elementName + '__pop';

const viewElementClass = 'stage-photos__view';
let view;
let photosSwiper;
let galleryUrl;

let opened = false;

let init = function() {
    initSwiper();
    view = $( '.' + viewElementClass );
    pop.inactiveHover( popUpSelector, inactiveClass, wasInactiveClass );
    //pop.hidePopups( activeClass );
    pop.expandable( popUpSelector, activeClass, wasInactiveClass, null, closeGallery, clickGallery );
    //attachClickGallery();
    galleryUrl = $( '.' + elementName ).data( 'album-source' );
};

var initSwiper = function() {
    let galeriesSwiper = new Swiper( '.stage-photos__container', {

        nextButton: '.stage-photos__button-list--prev',
        prevButton: '.stage-photos__button-list--next',
        slidesPerView: 4,
        spaceBetween: 10

    } );

    photosSwiper = new Swiper( '.stage-photos__view', {
        nextButton: '.stage-photos__button-view--prev',
        prevButton: '.stage-photos__button-view--next',
        slidesPerView: 'auto',
        spaceBetween: 10,
        lazyLoading: true,
        lazyLoadingInPrevNextAmount: 2
        //centeredSlides: true
    } );
};

const loadGallery = function( dir ) {
    $.get( galleryUrl + dir, function( data ) {
        $( '.stage-photos__button-view' ).css('visibility', 'visible');
        opened = true;
        if(photosSwiper) {
            photosSwiper.removeAllSlides();
            photosSwiper.appendSlide(data);
            view.addClass(viewElementClass + '--open');

            // Scroll when animation of height is over
            setTimeout( scrollToSectionEnd, 1000 );

            $( view ).imagesLoaded( function() {
                if (photosSwiper) {
                    photosSwiper.update();
                }
            } );
        }
    })
};

const scrollToSectionEnd = function() {
    let section = $( '.' + elementName );
    $( window ).scrollTo( section.offset().top + section.outerHeight() - $( window ).innerHeight(), 500);
}

const closeGallery = function ( that ) {
    $( '.stage-photos__button-view' ).css('visibility', 'hidden');
    opened = false;
    photosSwiper.removeAllSlides();
    view.removeClass(viewElementClass + '--open');
};

const clickGallery = function( that ) {
    loadGallery( that.data('album') );
};


export { init };
