import $ from 'jQuery';
import Swiper from 'Swiper';
import * as pop from '../pop-item/_pop-item';

const elementName = 'video';

const activeClass = elementName + '__pop--active';
const inactiveClass = elementName + '__item--inactive';
const wasInactiveClass = elementName + '__item--wasInactive';
const popUpSelector = '.' + elementName + '__pop';

var opened = false;

const init = function() {
    initSwiper();
    pop.inactiveHover( popUpSelector, inactiveClass, wasInactiveClass );
    //pop.hidePopups( activeClass );
    pop.expandable( popUpSelector, activeClass, wasInactiveClass, null, hideVideo, showVideo );

};

var initSwiper = function() {
    let videosSwiper = new Swiper( '.video__container', {
        nextButton: '.video__button--prev',
        prevButton: '.video__button--next',
        slidesPerView: 4,
        spaceBetween: 10

    } );
};

const showVideo = function( that ) {
    if( !opened ) {
        $( '.video__view' ).addClass( 'video__view--open' );
    }
    opened = true;
    let video = that.data('video');
    video += '&autoplay=1';
    let wrapper = $('.video__iframe-wrapper');
    wrapper.html('<iframe class="video__iframe" src="' + video + '" frameborder="0" allowfullscreen></iframe>');
    $( window ).scrollTo( wrapper.offset().top + wrapper.outerHeight() - $( window).innerHeight() + 10, 800);
};

const hideVideo = function() {
    opened = false;
    $( '.video__view' ).removeClass( 'video__view--open' );
    $('.video__iframe-wrapper').html('');
}

export { init };
