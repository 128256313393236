import $ from 'jQuery';
import {init as newsInit} from '../news/_news';

var init = function() {
    let button = $( '.section-news__more' );
    let source = button.data('source');
    $( button.click( function() {
        let count = $('.section-news__item').length;
        $.post(source, { from: count }, function(data) {
            $('.section-news__container').append(data);
            if(data.length) {
                if($('.section-news__item').length % 4) {
                    button.hide();
                }
                newsInit();
                $( 'body').trigger('sectionsResize');
            } else {
                button.hide();
            }
        });
    } ) );
};



export { init };
