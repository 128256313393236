/*******************************************************************************
 * Common helpers
 ******************************************************************************/
import { init as initPolyfills } from './commons/_polyfills';
initPolyfills();
/******************************************************************************/

/*******************************************************************************
 * Components
 ******************************************************************************/

import  * as paper  from './components/paper/_paper';
paper.listen();

import { init as initScheduleItem } from './components/schedule-item/_schedule-item';
initScheduleItem();

import { init as initSchedule } from './components/section-schedule/_section-schedule';
initSchedule();

import { init as initNews } from './components/news/_news';
initNews();

import { init as initNewsMore } from './components/section-news/_section-news';
initNewsMore();

import { init as initMenu } from './components/menu/_menu';
initMenu();

import { init as initMore } from './components/section-bio/_section-bio';
initMore();

import { init as initFlipCard } from './components/image-flipped/_image-flipped';
initFlipCard();

import { init as initStagePhotos } from './components/stage-photos/_stage-photos';
initStagePhotos();

import { init as initPortraits } from './components/portraits/_portraits';
initPortraits();

import { init as initVideo } from './components/video/_video';
initVideo();

/******************************************************************************/

/*******************************************************************************
 * Layouts
 ******************************************************************************/

/******************************************************************************/
