import $ from 'jQuery';

const init = function() {


    $( '.news' ).click( function(){
        let source = $( this ).data('news-source');
        let paper = $( this ).data('paper');
        load(source, paper);
    });

    $('.news__more').click( function(e){
        //e.preventDefault();
        //$( this).closest( '.news' ).trigger('click');
    } );

};

const load = function(source, paper) {
    $.get(source, function(data) {
        let callbacks = {
            //'next': function() {
            //    load('/base.css', paper);
            //},
            //'prev': function() {
            //    load('/base.css', paper);
            //}
        };
        $( paper ).trigger('showPaper', [ data.content, callbacks ]);
    }, 'json');
};

export { init };